<template>
  <n-spin :show="loading">
    <n-card hoverable segmented>
      <template #header>
        <i class="material-icons">{{ versionIcon }}</i>
        <span class="title">{{ version.version }}</span>
      </template>
      <template #header-extra v-if="version.latest">
        <i class="material-icons material-icons--small">new_releases</i>
      </template>
      <div>Type: {{ version.type }}</div>
      <div>Release: {{ moment(version.releaseTime).format('DD.MM.YYYY') }}</div>
      <div>Size: {{ fileSize(version.size) }}</div>
      <template #action>
        <div class="actions">
          <div class="action" v-if="!version.installed">
            <n-button
              :disabled="loading"
              class="button"
              @click="installVersion"
            >
              <template #icon>
                <i class="material-icons material-icons--small">download</i>
              </template>
              Install
            </n-button>
          </div>
          <div class="action" v-if="version.installed">
            <n-popconfirm @positive-click="deleteWorld">
              <template #trigger>
                <div>
                  <n-button
                    type="error"
                    ghost
                    :disabled="version.running"
                    class="button"
                  >
                    <template #icon>
                      <i class="material-icons material-icons--small">public</i>
                    </template>
                    Delete World
                  </n-button>
                </div>
              </template>
              Really?
            </n-popconfirm>
          </div>
          <div class="action" v-if="version.installed">
            <n-popconfirm @positive-click="deleteVersion">
              <template #trigger>
                <div>
                  <n-button
                    type="error"
                    ghost
                    :disabled="version.running"
                    class="button"
                  >
                    <template #icon>
                      <i class="material-icons material-icons--small">delete</i>
                    </template>
                    Delete
                  </n-button>
                </div>
              </template>
              Really?
            </n-popconfirm>
          </div>
        </div>
      </template>
    </n-card>
  </n-spin>
</template>

<script>
import api from '@/api';
import FileSize from '../utilities/FileSize';
import moment from 'moment';

export default {
  name: 'VersionCard',
  props: {
    version: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    versionIcon() {
      switch (this.version.type) {
        case 'release':
          return 'apps';
        case 'snapshot':
          return 'photo_camera';
        default:
          return 'error';
      }
    },
  },
  methods: {
    moment,
    fileSize: FileSize,
    async installVersion() {
      this.loading = true;
      await api
        .post('versions', {
          version: this.version.version,
        })
        .then(() => {
          this.$emit('update', { ...this.version, installed: true });
        })
        .catch(error => {
          console.log(error);
        });
      this.loading = false;
    },
    async deleteVersion() {
      this.loading = true;
      await api
        .delete(`versions/${this.version.version}`)
        .then(() => {
          this.$emit('update', { ...this.version, installed: false });
        })
        .catch(error => {
          console.log(error);
        });
      this.loading = false;
    },
    async deleteWorld() {
      this.loading = true;
      await api.delete(`world/${this.version.version}`).catch(error => {
        console.log(error);
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding: 0 5px;
  vertical-align: middle;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.action {
  &:not(:first-child) {
    margin-left: 20px;
  }
}
</style>
