<template>
  <div class="versions">
    <div class="filter">
      <div class="search">
        <n-input v-model:value="query" placeholder="Search version..." />
      </div>
      <div class="checkbox">
        <n-checkbox v-model:checked="release">Release</n-checkbox>
      </div>
      <div class="checkbox">
        <n-checkbox v-model:checked="snapshot">Snapshot</n-checkbox>
      </div>
    </div>
    <div class="list">
      <div v-for="item in filteredVersions" :key="item.version" class="item">
        <version-card :version="item" @update="updateItem"></version-card>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import VersionCard from '../components/VersionCard';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  data() {
    return {
      data: [],
    };
  },
  setup() {
    return {
      query: ref(''),
      snapshot: ref(false),
      release: ref(true),
    };
  },
  computed: {
    filteredVersions() {
      const isTypeSelected = type => {
        if (this.snapshot && type === 'snapshot') {
          return true;
        }
        return this.release && type === 'release';
      };
      if (this.data) {
        return this.data.filter(item => {
          return isTypeSelected(item.type) && item.version.includes(this.query);
        });
      }
      return [];
    },
  },
  components: {
    VersionCard,
  },
  methods: {
    updateItem(e) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === e.id) {
          this.data[i] = e;
          return;
        }
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    await api
      .get('versions')
      .then(response => {
        next(vm => (vm.data = response.data));
      })
      .catch(error => {
        switch (error.response.status) {
          case 401:
            next('/login');
            break;
          default:
            next(`/error/${error.response.status}`);
        }
      });
  },
});
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.item {
  flex: 0 1 200px;
  margin: 10px;
}

.filter {
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  .checkbox {
    flex: 0 1 auto;
    margin-left: 10px;
  }
  .search {
    flex: 1 1 auto;
  }
}
</style>
